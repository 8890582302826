import {makeStyles} from '@material-ui/core/styles'

/**
 * Component-scoped styles which should be used as a hook
 * inside QuotationOverview functional component
 */

const useStyles = makeStyles((theme) => ({
	root: {
		backgroundColor: '#edf2f8',
	},
	headerBox: {
		marginTop: theme.spacing(52 / 8),
		display: 'flex',
		justifyContent: 'space-between',
		gap: theme.spacing(16 / 8),
	},
	title: {
		fontSize: 36,
		fontWeight: 'bold',
	},
	subTitle: {
		color: '#7e7e80',
		fontSize: 16,
		fontWeight: 500,
		marginTop: theme.spacing(12 / 8),
	},
	headerButtons: {
		display: 'flex',
		alignItems: 'center',
	},
	createButton: {
		width: 200,
		height: 48,
	},
	tableValue: {
		fontSize: 16,
		color: '#08080d',
	},
	purpleTableValue: {
		color: '#524fa2',
		fontWeight: 500,
	},
	tableBodyRow: {},
	searchInput: {
		marginTop: theme.spacing(16 / 8),
		marginLeft: `calc(70%)`,
		width: `calc(30%)`,
		marginBottom: theme.spacing(22 / 8),
		'& p': {
			color: '#7E7E80',
			fontWeight: 500,
			position: 'absolute',
		},
	},
	downloadText: {
		fontWeight: 600,
		color: '#524fa2',
		textDecoration: 'underline',
	},
	downloadLoadingText: {
		color: '#7e7e80',
		textDecoration: 'none',
	},
	searchLoading: {
		height: 12,
		width: 12,
	},
	bottomPadding: {
		height: theme.spacing(120 / 8),
	},
	icon: {
		height: theme.spacing(158 / 8),
	},
}))

export default useStyles
