import {Box, Grid, Typography} from '@material-ui/core'
import classNames from 'classnames'
import debounce from 'debounce'
import PropTypes from 'prop-types'
import React, {useContext, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'

import CustomButton from '@components/CustomButton'
import CustomPlaceholder from '@components/CustomPlaceholder'
import CustomTable, {ColumnData} from '@components/CustomTable'
import CustomTextInput from '@components/CustomTextInput'
import CyclisHeader from '@components/CyclisHeader'
import ProtectedAccessRight from '@components/ProtectedAccessRight'
import {TemplateContainerComponent} from '@components/index'
import {RootState} from '@redux/root-reducer'
import {navigate} from '@redux/slices/navigation-slice'
import {EmptyStateIconSvg, SearchIconSvg} from '@svg/index'
import {
	getQuotationDocumentDownloadUrl,
	getQuotations,
	getQuotationsNextPage,
	resetQuotationDocument,
} from '@templates/QuotationOverview/quotations.slice'
import {LanguageContext} from '@utils/context'
import {formatIsoToPrettyDate} from '@utils/date'
import {downloadFileFromUrl} from '@utils/download-helper'
import {useThrottledEffect} from '@utils/hooks'
import {SortDirection} from '@utils/hooks/TableDataProvider'
import {CommonProps, Quotation} from '@utils/types'
import {AccessRightsEnum} from '@utils/types/access-rights'

import useStyles from './style.hook'

/**
 * QuotationOverview
 */
interface QuotationOverviewLocation extends Location {
	state: {
		searchTerm?: string
		sort?: {
			direction: SortDirection
			property: string
		}
	}
}

interface QuotationOverviewProps extends CommonProps {
	location: QuotationOverviewLocation
}

const QuotationOverview: React.FC<QuotationOverviewProps> = ({location}) => {
	// Get styles from component-scoped styles hook
	const classes = useStyles()
	const dispatch = useDispatch()
	const {t} = useTranslation(undefined, {useSuspense: false})
	const {activeLanguage} = useContext(LanguageContext)

	const [searchTerm, setSearchTerm] = useState(
		(location.state && location.state.searchTerm) || ''
	)

	const [sort, setSort] = useState(
		(location.state && location.state.sort) || {
			direction: SortDirection.DESC,
			property: 'customer',
		}
	)

	const {firstName, lastName} = useSelector((state: RootState) => state.auth)
	const {
		quotations,
		paginationCursor,
		paginationHasMoreData,
		success: quotationsSuccess,
		loading: quotationsLoading,
		documentSuccess,
		documentLoading,
		documentDownloadUrl,
		documentQuotationId,
	} = useSelector((state: RootState) => state.quotations)

	const rootRef = useRef<any>(null)

	const tableCells: ColumnData[] = [
		{
			id: 0,
			label: t('QuotationsOverViewTableHeaderSoNum'),
			propertyName: 'salesOrderNumber',
			noTranslate: true,
			toBodyClass: (): string =>
				classNames(classes.tableValue, classes.purpleTableValue),
			toBodyValue: (quotation: Quotation): string =>
				quotation.salesOrderNumber!,
			toTooltipValue: (quotation: Quotation): string =>
				quotation.salesOrderNumber!,
		},
		{
			id: 1,
			label: t('QuotationsOverViewTableHeaderCustomer'),
			propertyName: 'customer',
			noTranslate: true,
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (quotation: Quotation): string => quotation.customer!,
			toTooltipValue: (quotation: Quotation): string =>
				quotation.customer!.toString(),
		},
		{
			id: 2,
			label: t('QuotationsOverViewTableHeaderCyclist'),
			propertyName: 'cyclistFirstName',
			noTranslate: true,
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (quotation: Quotation): string =>
				`${quotation.cyclistFirstName} ${quotation.cyclistLastName}`,
			toTooltipValue: (quotation: Quotation): string =>
				`${quotation.cyclistFirstName} ${quotation.cyclistLastName}`,
		},
		{
			id: 3,
			label: t('QuotationsOverViewTableHeaderBicycle'),
			propertyName: 'brand',
			noTranslate: true,
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (quotation: Quotation): string =>
				`${quotation.brand} ${quotation.model || 'N/A'}`,
			toTooltipValue: (quotation: Quotation): string =>
				`${quotation.brand} ${quotation.model || 'N/A'}`,
		},
		{
			id: 4,
			label: t('QuotationOverviewHeaderDate'),
			propertyName: 'createDate',
			toBodyClass: (): string => classNames(classes.tableValue),
			toBodyValue: (quotation: Quotation): string =>
				formatIsoToPrettyDate(quotation.createDate!) || 'N/A',
			toTooltipValue: (quotation: Quotation): string =>
				formatIsoToPrettyDate(quotation.createDate!) || 'N/A',
		},
		{
			id: 5,
			label: t('QuotationOverviewDownload'),
			propertyName: '',
			toBodyClass: (quotation: Quotation): string =>
				(quotation.attachments && quotation.attachments.length
					? documentLoading && quotation.quotationId === documentQuotationId
						? classNames(classes.downloadText, classes.downloadLoadingText)
						: classNames(classes.tableValue, classes.downloadText)
					: null) as any,
			toBodyValue: (quotation: Quotation): string =>
				quotation.attachments && quotation.attachments.length
					? documentLoading && quotation.quotationId === documentQuotationId
						? t('QuotationOverviewDocumentLoading')
						: t('QuotationOverviewDownload')
					: '',
			toTooltipValue: (quotation: Quotation): string =>
				quotation.attachments && quotation.attachments.length
					? documentLoading && quotation.quotationId === documentQuotationId
						? t('QuotationOverviewDocumentLoading')
						: t('QuotationOverviewDownload')
					: '',
			sortable: false,
		},
	]

	const handleScrollPagination = (): void => {
		if (rootRef.current) {
			const scrollTopMax =
				rootRef.current.scrollHeight - rootRef.current.clientHeight
			const scrollPercent = (rootRef.current.scrollTop / scrollTopMax) * 100

			if (scrollPercent >= 30 && paginationHasMoreData && !quotationsLoading) {
				dispatch(
					getQuotationsNextPage(
						paginationCursor,
						searchTerm,
						sort.property,
						sort.direction
					)
				)
			}
		}
	}

	const changeSort = (property: string): void => {
		// Flip sorting order if same property
		if (property === sort.property) {
			setSort({
				...sort,
				direction:
					sort.direction === SortDirection.DESC
						? SortDirection.ASC
						: SortDirection.DESC,
			})
		} else {
			// Else set new property as sorting property and default descending order
			setSort({
				direction: SortDirection.DESC,
				property,
			})
		}
	}

	const handleCellClick = (quotation: Quotation, index: number): void => {
		if (index === tableCells.length - 1) {
			if (quotation.attachments && quotation.attachments.length) {
				dispatch(
					getQuotationDocumentDownloadUrl(
						quotation.quotationId!,
						quotation.attachments[0].documentId
					)
				)
			}
		} else {
			dispatch(
				navigate(
					`/${activeLanguage}/app/quotations/detail?id=${quotation.quotationId}`,
					{
						previousLocation: {language: activeLanguage},
						state: {
							sort,
							searchTerm,
						},
					}
				)
			)
		}
	}

	/**
	 * Start the browser download once there's a document download url available
	 */
	useEffect(() => {
		if (documentSuccess && documentDownloadUrl) {
			downloadFileFromUrl(documentDownloadUrl)
		}
	}, [documentSuccess, documentDownloadUrl])

	/**
	 * Reset document state on unmount
	 */
	useEffect(() => {
		return (): void => {
			dispatch(resetQuotationDocument())
		}
	}, [])

	/**
	 * Fetch new quotations result when sort changes
	 */
	useEffect(() => {
		dispatch(
			getQuotations(undefined, searchTerm, sort.property, sort.direction)
		)
	}, [sort])

	useThrottledEffect(
		() => {
			if ((searchTerm && searchTerm.length >= 3) || searchTerm === '') {
				dispatch(
					getQuotations(undefined, searchTerm, sort.property, sort.direction)
				)
			}
		},
		1000,
		[searchTerm]
	)

	return (
		<TemplateContainerComponent
			id="quotations-overview-template"
			className={classes.root}
			propsToDelegate={{
				onScroll: debounce(handleScrollPagination, 600),
				ref: rootRef,
			}}
		>
			<CyclisHeader customerName={`${firstName} ${lastName}`} />
			<Grid container spacing={8}>
				<Grid item xs={1} />
				<Grid item xs={10}>
					<Box className={classes.headerBox}>
						<Box>
							<Typography className={classes.title} variant={'h1'}>
								{t('QuotationsOverViewTitle')}
							</Typography>
							<Typography className={classes.subTitle} variant={'body1'}>
								{t('QuotationsOverViewSubTitle')}
							</Typography>
						</Box>
						<ProtectedAccessRight
							requiredAccessRight={AccessRightsEnum.CREATE_QUOTATION}
						>
							<Box className={classes.headerButtons}>
								<CustomButton
									className={classes.createButton}
									id={`quotations-overview-create-button`}
									text={t('QuotationsOverViewCreateButton')}
									propsToDelegate={{
										onClick: (): void => {
											dispatch(
												navigate(`/${activeLanguage}/app/quotations/new`, {
													previousLocation: {language: activeLanguage},
												})
											)
										},
									}}
								/>
							</Box>
						</ProtectedAccessRight>
					</Box>
					<CustomTextInput
						id={`bicycle-overview-search-input`}
						className={classes.searchInput}
						iconLeft={<SearchIconSvg />}
						helperText={
							(searchTerm &&
								searchTerm.length < 3 &&
								t('SearchAtleast3Characters')) as string
						}
						helperTextId={'bicycle-overview-search-helper-text'}
						propsToDelegate={{
							placeholder: t('BicycleOverviewSearchInputPlaceholder'),
							value: searchTerm,
							onChange: (e): void => setSearchTerm(e.target.value),
						}}
					/>
					<CustomTable
						id={'quotations'}
						columnsData={tableCells}
						tableEntries={quotations}
						sort={sort}
						handleCellClick={handleCellClick}
						changeSort={changeSort}
						placeholder={
							quotationsSuccess &&
							!quotationsLoading &&
							quotations.length === 0 && (
								<CustomPlaceholder
									id={'quotations-overview'}
									icon={<EmptyStateIconSvg className={classes.icon} />}
									headerText={t('QuotationOverviewCustomPlaceholderHeader')}
									subTitle={t('QuotationOverviewCustomPlaceholderSubTitle')}
								/>
							)
						}
					/>
				</Grid>
				<Grid item xs={1} />
			</Grid>
			<Box className={classes.bottomPadding} />
		</TemplateContainerComponent>
	)
}

QuotationOverview.propTypes = {
	location: PropTypes.any,
}

export default QuotationOverview
